import React, { useEffect, useLayoutEffect, useState } from "react";
import Select from 'react-select';
import { useAppSelector } from "../../store/storeHooks";
export interface Props {getData : any , setData : Function}
export const SendorBox:React.FC<Props> = ({getData, setData}) => {
    const userInfo = useAppSelector(state=>state.loginUser.userInfo)
    const [isActive, setActive] = useState<boolean>(false)
    const handleChange = (e:any) => {
        if(isActive){
            if(getData?.managerList){
                const match = e.value.match(/^(\d+),([^()]+)\(([^)]+)\)$/);
                setData((prev:any)=>({...prev, senderId : match[1], senderName : match[2], senderEmail : match[3]}))
            }
        } else{
            if(getData?.managerList){
                const nameAndEmail = `${getData?.managerList[0]?.managerId},${getData?.managerList[0]?.managerNameKr}`
                const match : any = nameAndEmail.match(/^(\d+),([^()]+)\(([^)]+)\)$/);
                setData((prev:any)=>({...prev, senderId : match[1], senderName : match[2], senderEmail : match[3]}))
            }
        }
    }
    useLayoutEffect(()=>{
        if(getData?.managerList){
            const nameAndEmail = `${getData?.managerList[0]?.managerId},${getData?.managerList[0]?.managerNameKr}`
            if(nameAndEmail){
                const match = nameAndEmail.match(/^(\d+),([^()]+)\(([^)]+)\)$/)
                if(match){
                    setData((prev:any)=>({...prev, senderId : match[1], senderName : match[2], senderEmail : match[3]}))
                }
            }
        }
    }, [getData?.managerList])
    return(
        <>
        <tr>
            <th>보내는 사람</th>
            <td className="company">
                <div className="flexBox radioList">
                    <div className="radioBox" onClick={()=>setActive(false)}>
                        <input type="radio" name="sender" onClick={handleChange} id="me" checked={!isActive ? true : false}/>
                        <label htmlFor="me">{`${userInfo?.name} (${userInfo?.account})`}</label>
                    </div>
                    <div className="radioBox">
                        <div>
                            <div onClick={()=>setActive(true)}>
                                <input type="radio" name="sender" id="managers" checked={isActive ? true : false}/>
                                <label htmlFor="managers">회사계정</label>
                            </div>
                            {/* <!-- 회사계정이 체크인 상태 일 때 selectBox에 active클래스 추가 --> */}
                            <div className="selectBox active">
                            <Select
                                value={getData?.sendorList?.find((x:any) => x.value === 0)}
                                name="supplier"
                                options={getData?.sendorList}
                                className="selectBox1"
                                onChange={handleChange}
                                isSearchable={true}
                                placeholder={'계정 선택하기'}
                                styles={{
                                    control : (baseStyles : any, state : any) =>({
                                        ...baseStyles, opacity : !isActive ? '0.5' : '1'
                                    })
                                }}
                                isDisabled={!isActive ? true : false}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
        </>
    )
}